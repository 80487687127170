import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import "./pager.module.scss"
import headerStyles from "./pager.module.scss"
 
const PagerBlog = ({ pageContext }) => {

  const res = useStaticQuery(graphql`
		query {
			allBlog {
				edges {
				  node {
					slug
					id
				  }
				} 
			}
		}
  `)
  
  const { previousPagePath, nextPagePath } = pageContext;
 
  return (
    <ul className={ `uk-pagination ${headerStyles.pagerBox}` } uk-margin="true">
      {nextPagePath && (
        <span className={ `${headerStyles.pagerNext}` }><Link to={nextPagePath}>Следующая ›</Link></span>
      )}

      {Array.from({ length: Math.ceil(res.allBlog.edges.length / pageContext.limit) }, (_, i) => (
        <a className={ `${headerStyles.pagerNumber}` } key={`pagination-number${i + 1}`} href={`/blog/${i === 0 ? "" : i + 1}`} >
          {i + 1} 
        </a>
      ))}

      {previousPagePath && (
        <span className={ `${headerStyles.pagerPrevious}` }><Link to={previousPagePath}>‹ Предыдущая</Link></span>
      )}
      
    </ul>
  );
};
 
PagerBlog.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
 
export default PagerBlog;
