import React from "react"

import PropTypes from 'prop-types';
import PagerBlog from "../components/pagerBlog";

import Layout from "../components/layout"
import { graphql } from "gatsby"

import "./blog.module.scss"
import headerStyles from "./blog.module.scss"

import Seo from "../components/seo"
import Header from "../components/header"
import Sdelka  from "../components/sdelka"
import Uryslygi from "../components/uryslygi"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"


import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"

const Blogs = ({ data, pageContext}) => {

	const blogs = data.blogs.edges;

	return (
		<Layout>
			<Seo title="Юридический блог" description="Блог сотрудников Алмаз Казань. Полезная информация от юристов Казани и РТ!" />


			<Header title="Юридический блог" card="Блог сотрудников Алмаз Казань. Полезная информация от юристов Казани и РТ!" />

			
			<div className={ `uk-section uk-section-xsmall` }>
            	<div className={ `uk-container` }>

					<ul className={ `uk-breadcrumb` }>
						<li><a href="/">Главаная</a></li>
						<li><span>Блог</span></li>
					</ul>

				</div>
			</div>

		
			<div className={ `uk-section uk-section-xsmall ${headerStyles.Fon}` }>
            	<div className={ `uk-container` }>
					<img className={ `${headerStyles.Color}` } src="/img/city1.png" alt="Агентство недвижимости Алмаз Казань"></img>

                	<h2 className={ `${headerStyles.BlogHeader}` } >Блог</h2>

                	<div className={ `uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-3@xl uk-grid uk-padding-small uk-margin-medium-top` } uk-grid="true">

						{blogs.map((edge) => {
							return (
								<div key={edge.node.slug}>
									<div className={ `uk-card uk-card-default ${headerStyles.BlogCard}` } >
										<a className={ `uk-card uk-card-default ${headerStyles.BlogCardLink}` } href={`/blog/${edge.node.slug}`}>
											<div className={ `uk-card-media-top` } >
												<img className={ `${headerStyles.BlogImg}` } src={`${edge.node.imgMini}`} alt={edge.node.title}></img>
											</div>
											<div className={ `uk-card-body` }>
												<h4 className={ `uk-card-title ${headerStyles.BlogCardTitle}` } >{edge.node.title}</h4>
												<p className={ `uk-card-title ${headerStyles.BlogCardDescription}` }>{edge.node.card}</p>

												<hr></hr>
																					
												<div>
													<span className={ `uk-card-title ${headerStyles.BlogCardDate}` } ><span uk-icon="calendar"></span> {edge.node.date}</span>
													<span className={ `uk-margin-medium-left uk-card-title ${headerStyles.BlogCardDate}` } ><span uk-icon="clock"></span> {edge.node.time}</span>
												</div>

												<div className={ `uk-margin-small-top` }>
													<span className={ `uk-card-title ${headerStyles.BlogCardDate}` } >
														<img className={ `${headerStyles.BlogCardDateImg}` } src={`${edge.node.ava}`} alt={edge.node.author}></img>
														{edge.node.author}
													</span>
												</div>

											</div>
										</a>
									</div>
								</div>
							)
						})}

					</div>

					<PagerBlog pageContext={pageContext} />
					
				</div>
			</div>
			
			<Uryslygi />
			<Sdelka />
			<Price />
			
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
		
			<Social />
			<Certificate />
			<Users />

			<Map />

			<Sale />
			<Call />
			<CallForm />
			<ContactForm />
			<Stado />

		</Layout>
	)
}

Blogs.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
}; 
  
export const query = graphql`
	query($skip: Int!, $limit: Int!) {
		blogs: allBlog(limit: $limit, skip: $skip, sort: { order: DESC, fields: datePublished }) {
			edges {
				node {
					slug
					title
					description
					card
					img
					imgMini
					date
					seoTitle
					seoDescription
					author
					ava
					time
				}
			} 
		}

		blogComponent: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

export default Blogs
